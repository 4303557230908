<template>
  <v-app>
  <v-navigation-drawer
     v-model="drawer"
     app
   >
         <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
            </v-list-item-title>
            <v-list-item-subtitle>
                <v-spacer></v-spacer>

                <v-img
                  max-height="110"
                  max-width="180"
                  src="../src/assets/solarvent.png"
                ></v-img>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list
          dense
          nav
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
          >
          <router-link style="display:flex" tag="div" class="col" class-active="active" :to="item.url" exact>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </router-link>
          </v-list-item>
        </v-list>
   </v-navigation-drawer>

   <v-app-bar app>
     <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

     <v-toolbar-title>
     <v-spacer></v-spacer>
     </v-toolbar-title>
     <v-img
       v-if="!drawer"
       max-height="110"
       max-width="180"
       src="../src/assets/solarvent.png"
     ></v-img>
   </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    drawer: null,
    items: [
          { title: 'Überblick', icon: 'mdi-view-dashboard', url: '/' },
          { title: 'QS', icon: 'mdi-truck-fast', url: '/qs' },
          { title: 'Chat', icon: 'mdi-chat-processing-outline', url:'/videoChat' },
          { title: 'Login', icon: 'mdi-login', url:'/login' },
        ],
  }),
  mounted(){

  },
  methods:{

  }
};
</script>
