import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/startView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'StartView',
    component: HomeView
  },
  {
   path: '/qs',
   name: 'QsView',
   component:  () => import(/* webpackChunkName: "about" */ '../views/QsView.vue')
 },
  {
   path: '/login',
   name: 'LoginView',
   component:  () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
 },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  const userIsLoggedIn = localStorage.getItem('logged_in');
  const token = localStorage.getItem('login_token');
  console.log(to)
  if (1==2 && userIsLoggedIn !== 1 && token == null && to.name !== "LoginView") {
    next({ name: 'LoginView' })
  } else {
    next()
  }
})

export default router
