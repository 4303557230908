<template>
       <div>
       <v-alert
          outlined
          type="info"
          prominent
          border="left"
          color="black"
          >
          Überblick aller verfügbaren App's
          </v-alert>
          <v-item-group mandatory>
             <v-container>
               <v-row>
                 <v-col
                   cols="12"
                   md="4"
                 >
                 <router-link style="cursor:pointer;" tag="div" to="/qs" exact>
                   <v-item>
                     <v-card
                       class="d-flex align-center"
                       dark
                       color="rgb(255 205 15)"
                       height="200"
                     >
                       <v-scroll-y-transition>
                         <div
                           class="text-h2 flex-grow-1 text-center"
                           style="font-size:40px!important;display:flex;flex-direction:column;"
                         >
                         <v-icon
                             large
                             color="white"
                           >
                             mdi-truck-fast
                           </v-icon>
                           Qualitäts- Sicherung
                         </div>
                       </v-scroll-y-transition>
                     </v-card>
                   </v-item>
                  </router-link>
                 </v-col>
                 <v-col
                   cols="12"
                   md="4"
                 >
                 <router-link style="cursor:pointer;" tag="div" to="/videoChat" exact>
                   <v-item>
                     <v-card
                       class="d-flex align-center"
                       color="rgb(255 205 15)"
                       dark
                       height="200"

                     >
                       <v-scroll-y-transition>
                         <div
                           class="text-h2 flex-grow-1 text-center"
                           style="font-size: 40px!important;display: flex;flex-direction: column;"
                         >
                         <v-icon
                             large
                             color="white"
                           >
                             mdi-chat-processing-outline
                           </v-icon>
                           Video & Chat
                         </div>
                       </v-scroll-y-transition>
                     </v-card>
                   </v-item>
                   </router-link>
                 </v-col>
               </v-row>
             </v-container>
           </v-item-group>
       </div>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({

    }),
  }
</script>
